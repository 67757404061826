import styled from "styled-components";
import {
	COLOR_WHITE,
	DESKTOP_SM_BP,
	MOBILE_L_BP,
	MOBILE_M_BP,
} from "@website-builder/utilities-index";

export const Wrapper = styled.section`
	display: flex;
	flex-flow: row nowrap;
	color: ${({ fontColor = COLOR_WHITE }) => fontColor};
	@media only screen and (max-width: ${MOBILE_L_BP - 1}px) {
		flex-flow: row wrap;
		justify-content: center;
	}
`;

export const PoweredByText = styled.div`
	flex-basis: 30%;
	padding: 20px;
	@media only screen and (max-width: ${DESKTOP_SM_BP}px) {
		margin-right: 10px;
		padding: 10px;
	}
	@media only screen and (max-width: ${MOBILE_L_BP - 1}px) {
		margin-bottom: 10px;
	}
	@media only screen and (max-width: ${MOBILE_L_BP - 1}px) {
		flex-basis: 100%;
	}
`;

export const Description = styled.div`
	flex-basis: 70%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	@media only screen and (max-width: ${MOBILE_L_BP - 1}px) {
		flex-basis: 100%;
	}
`;
