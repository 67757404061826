import React from "react";
import { Wrapper, PoweredByText, Description } from "./style";

const PoweredBy = ({ poweredBy, description, fontColor }) => {
	return (
		<Wrapper fontColor={fontColor}>
			<PoweredByText>{poweredBy}</PoweredByText>
			<Description>{description}</Description>
		</Wrapper>
	);
};

export default PoweredBy;
